import { transform } from 'framer-motion';
import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';

export const ScoreModuleSelect = ({ options, placeholder, onChange, value, defaultValue, img }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 769);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 769);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: isMobile ? '120px' : '150px',
            fontFamily: 'Circe',
            zIndex: '20',
            borderRadius: '10px',
            border: 'none',
            textAlign: 'left',
            boxShadow: state.isFocused ? '0' : '0',
            opacity: state.isDisabled ? 0.5 : 1,
            backgroundColor: state.isDisabled ? '#fff' : '#fff',
            height: isMobile ? '30px' : '35px',
            minHeight: isMobile ? '30px' : '35px'
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '10px',
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: state.isSelected ? '#6BC4E8' : '#fff',
            color: state.isSelected ? '#fff' : '#6BC4E8',
            '&:hover': {
                backgroundColor: state.isSelected ? '#6BC4E8' : '#DAF0F9'
            },
            fontWeight: 'bold'
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            paddingLeft: '10px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            height: isMobile ? '30px' : '35px'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'bold',
            color: '#6BC4E8'
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            fontFamily: 'Circe',
            color: '#6BC4E8',
            fontWeight: 'bold'
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left',
            overflow: 'hidden'
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            borderRadius: '10px',
            textAlign: 'left',
            zIndex: 9999,
            fontFamily: 'Circe',
            fontSize: '14px',
            border: 'none',
            overflow: 'hidden',
            boxShadow: 'none',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.4)'
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
            borderColor: '#B3B3B3'
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: '10px',
            transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
        }),

    };

    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <img src={img} alt="Dropdown Icon" />
        </components.DropdownIndicator>
    );

    return (
        <Select
            options={options}
            styles={selectStyles}
            isSearchable={false}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            components={{ DropdownIndicator }}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
        />
    );
};
