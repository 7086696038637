import { motion } from "framer-motion";

const CompletionCircle = ({ completionPercentage }) => {
    const size = 100; // Размер SVG
    const strokeWidth = 8; // Толщина линии
    const radius = (size - strokeWidth) / 2; // Радиус круга
    const circumference = 2 * Math.PI * radius; // Длина окружности
    const progress = (completionPercentage / 100) * circumference; // Длина заполненной дуги

    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            {/* Фон круга */}
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke="#e0e0e0"
                strokeWidth={strokeWidth}
            />
            {/* Заполняемый круг */}
            <motion.circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke="#A8E86B"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeDasharray={circumference}
                strokeDashoffset={circumference - progress}
                initial={{ strokeDashoffset: circumference }}
                animate={{ strokeDashoffset: circumference - progress }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            />
            {/* Анимированная галочка */}
                <motion.path
                    d="M28 52 L45 70 L75 30" // Улучшенный путь галочки
                    fill="none"
                    stroke="#A8E86B"
                    strokeWidth="8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="80"
                    strokeDashoffset="80"
                    initial={{ strokeDashoffset: 80 }}
                    animate={{ strokeDashoffset: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                />
        </svg>
    );
};

export default CompletionCircle;