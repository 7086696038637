import React, { useState } from 'react';
import axios from 'axios';
import './DragAndDrop.css';
import { getToken } from '../../utils/cookieHandlers';

const DragDropFileInput = () => {
    const [dragging, setDragging] = useState(false);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];
            if (validateFile(droppedFile)) {
                setFile(droppedFile);
                setFileName(droppedFile.name);
                setUploadMessage('');
            }
        }
    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && validateFile(selectedFile)) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setUploadMessage('');
        }
    };

    const validateFile = (file) => {
        const allowedExtensions = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (!allowedExtensions.includes(file.type)) {
            setUploadMessage(<span style={{fontWeight: 'bold', color: 'red'}}>Разрешены только файлы Excel (.xls, .xlsx).</span>);
            setFile(null);
            setFileName('')
            return false;
        }
        return true;
    };

    const handleFileSubmit = async () => {
        if (!file) {
            setUploadMessage(<span style={{fontWeight: 'bold', color: 'red'}}>Пожалуйста, загрузите файл.</span>);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const token = getToken();
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/exception_profile_for_quiz/`,
                formData,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            setUploadMessage(<span style={{fontWeight: 'bold', color: 'green'}}>Файл успешно загружен!</span>);
            setFile(null);
            setFileName('')
        } catch (error) {
            setUploadMessage(<span style={{fontWeight: 'bold', color: 'red'}}>Ошибка загрузки файла.</span>);
            console.error(error.response?.data || error.message);
        }
    };

    return (
        <div className="draganddrop__div">
            <div
                className={`drag-drop-zone ${dragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input
                    type="file"
                    className="draganddrop__file-input"
                    onChange={handleFileInputChange}
                />
                <div className="draganddrop__content">
                    <p className="draganddrop__instruction">
                        Перетащите файл сюда или нажмите, чтобы выбрать
                    </p>
                    {fileName && (
                        <div className="draganddrop__file-info">
                            <p className="draganddrop__file-name">
                                Выбранный файл: <span style={{ fontWeight: 'bold' }}>{fileName}</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {uploadMessage && uploadMessage}
            <button
                className="draganddrop__submit-button"
                onClick={handleFileSubmit}
                disabled={!file}
            >
                Отправить файл
            </button>
        </div>
    );
};

export default DragDropFileInput;