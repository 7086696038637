import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import api from '../../utils/Api';
import loadingGif from '../../utils/images/loading.gif';
import './ReportModule.css';
import { DefaultSelect } from '../../components/Select/DefaultSelect';
import { ReactComponent as Close } from '../../utils/images/close.svg';
import { getToken } from '../../utils/cookieHandlers';
const ReportModule = ({ setOpened, isOpened }) => {
    const [quarter, setQuarter] = useState('');
    const [year, setYear] = useState('');
    const [reportType, setReportType] = useState('download_full_assessment_results');
    const [loading, setLoading] = useState(false);
    const popupRef = useRef();

    const quarters = [
        { label: '1 квартал', value: 'Q1', date: '-03-31' },
        { label: '2 квартал', value: 'Q2', date: '-06-30' },
        { label: '3 квартал', value: 'Q3', date: '-09-30' },
        { label: '4 квартал', value: 'Q4', date: '-12-31' },
    ];

    const reportTypes = [
        { label: 'Стандартный', value: 'download_full_assessment_results/?quarter=' },
        { label: 'Расширенный', value: 'detailed_results_report/?quarter=' },
        { label: 'Годовой', value: 'year_rereport?year=' },
    ];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);

        const currentMonth = new Date().getMonth();
        const initialQuarter = quarters[Math.floor(currentMonth / 3)].value;
        setQuarter(initialQuarter);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpened(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getFormattedDate = () => {
        const selectedQuarter = quarters.find(q => q.value === quarter);
        return selectedQuarter && year ? `${year}${selectedQuarter.date}` : '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedDate = getFormattedDate();
        if (formattedDate) {
            setLoading(true);
            try {
                const token = getToken();
                const response = await fetch(`${api._baseUrl}/quiz/${reportType}${reportType === 'year_rereport?year=' ? year : encodeURIComponent(formattedDate)}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (response.ok) {
                    const blob = await response.blob();
                    saveAs(blob, 'report.xlsx');
                } else {
                    console.error('Error fetching report:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching report:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const YearDropdown = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 21 }, (_, i) => ({
            label: `${currentYear - 10 + i}`,
            value: currentYear - 10 + i,
        }));

        return (
            <>
                <p>Год:</p>
                <DefaultSelect
                    options={years}
                    value={years.find((option) => option.value === year)}
                    onSelectedChange={(selectedOption) => setYear(selectedOption?.value || '')}
                    isMulti={false}
                    required={true}
                />
            </>
        );
    };


    return (
        <>
            <AnimatePresence>
                {isOpened &&
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'tween', duration: .3 }}
                        key='report-module-popup'
                        className='calibrate-popup__layout'>
                        <motion.div
                            className='report-module__div'
                            ref={popupRef}
                        >
                            <Close onClick={() => setOpened(false)} />
                            <form style={{paddingTop: '15px'}} onSubmit={handleSubmit}>
                                <p style={{ fontWeight: 'bold', position: 'absolute', top: '23px' }}>Выгрузка отчёта</p>
                                <div className='report-module__select-row'>
                                    <p>Тип отчета:</p>
                                    <DefaultSelect
                                        placeholder={'Выберите тип отчета'}
                                        options={reportTypes}
                                        onSelectedChange={(selected) => setReportType(selected.value)}
                                        isMulti={false}
                                        selectedValue={reportTypes.find(rt => rt.value === reportType)}
                                        isDisabled={loading}
                                        required={true}
                                    />
                                </div>
                                {reportType !== 'year_rereport?year=' && 
                                <div className='report-module__select-row'>
                                    <p>Квартал:</p>
                                    <DefaultSelect
                                        placeholder={'Выберите квартал'}
                                        options={quarters}
                                        onSelectedChange={(selected) => setQuarter(selected.value)}
                                        isMulti={false}
                                        selectedValue={quarters.find(q => q.value === quarter)}
                                        isDisabled={loading}
                                        required={true}
                                    />

                                </div>}
                                <div className='report-module__select-row'>
                                    <YearDropdown />
                                </div>
                                {loading && <span>Загрузка может занимать несколько минут.</span>}
                                <button
                                    type="submit"
                                    disabled={loading}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        backgroundColor: loading ? '#ccc' : '#6BC4E8',
                                        color: loading ? '#666' : '#fff',
                                    }}
                                >
                                    {loading ? <img src={loadingGif} alt="Loading..." style={{ width: '35px', height: '35px' }} /> : 'Отправить'}
                                </button>
                            </form>
                        </motion.div>
                    </motion.div>}
            </AnimatePresence >
        </>
    );
};

export default ReportModule;
