import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import './QuizStatusBar.css';
import cross from '../../utils/images/cross.svg';
import api from "../../utils/Api";
import { getToken } from "../../utils/cookieHandlers";

function DeleteQuizPopup({ isOpened, setIsOpened, quizId }) {
    const popupRef = useRef();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpened(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navigate]);

    const handleDeleteQuiz = async () => {
        try {
            setIsLoading(true);
            setDeleteStatus(null);
            const token = getToken();
            const res = await api.deleteQuiz(token, quizId);
            setDeleteStatus("success");
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                setIsOpened(false);
                navigate(-1);
            }, 2000);
        }
    };


    const renderContent = () => {
        if (deleteStatus === "success") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '25px', justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ fontSize: '18px' }}>Успешно удалено</h2>
                    <p>Анкета и заполненные ответы успешно удалены.</p>
                    <button className="delete-quiz__popup__agree-button" onClick={() => setIsOpened(false)}>Закрыть</button>
                </div>
            );
        }

        if (deleteStatus === "error") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '25px', justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ fontSize: '18px', color: 'red' }}>Ошибка</h2>
                    <p>Не удалось удалить анкету. Попробуйте позже.</p>
                    <button className="delete-quiz__popup__agree-button" onClick={() => setIsOpened(false)}>Закрыть</button>
                </div>
            );
        }

        return (
            <>
                <h2 style={{ fontSize: '18px' }}>Подтверждение удаления</h2>
                <p style={{ maxWidth: '80%' }}>Это действие приведет к <b>безвозратному&nbsp;удалению</b> анкеты и заполненных ответов.</p>
                <div className="delete-quiz__popup__buttons-block">
                    <button className="delete-quiz__popup__deny-button" disabled={isLoading} onClick={() => setIsOpened(false)}>Отменить</button>
                    <button className="delete-quiz__popup__agree-button" disabled={isLoading} onClick={handleDeleteQuiz}>
                        {isLoading ? "Удаление..." : "Удалить"}
                    </button>
                </div>
            </>
        );
    };

    return (
        <>
            <AnimatePresence>
                {isOpened && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        key={`delete-quiz__popup`}
                        className="popup__layout"
                    >
                        <div ref={popupRef} className="delete-quiz__popup__body">
                            <button className='calibrate-popup__close-button' onClick={() => setIsOpened(false)}>
                                <img src={cross} alt="Close" />
                            </button>
                            {renderContent()}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
}

export default DeleteQuizPopup;
