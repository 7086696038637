
import AuthModule from "../modules/AuthModule/AuthModule"
import CreateQuizModule from "../modules/CreateQuizModule/CreateQuizModule"
import SearchModule from "../modules/SearchModule/SearchModule"
import ProfileModule from "../modules/ProfileModule/ProfileModule"
import ProfileModuleParams from "../modules/ProfileModule/ProfileModuleParams"
import QuickQuizModule from "../modules/QuickQuizModule/QuickQuizModule"
import QuizModule from "../modules/QuizModule/QuizModule"
import ReportModule from "../modules/ReportModule/ReportModule"
import { PageTransition } from "./PageTransition"
import HRModule from "../modules/HRModule/HRModule"
import MethodologyPage from "../pages/MethodologyPage"



export const AuthPage = (props) => {
  return (
    <>
      <PageTransition>
        <AuthModule {...props} />
      </PageTransition>
    </>
  )
}

export const ProfilePage = (props) => {
  return (
    <>
      <PageTransition>
        <ProfileModule {...props} />
      </PageTransition>
    </>
  )
}

export const ProfilePageParams = ({ setIsLoading, ...props }) => {
  return (
    <>
      <PageTransition>
        <ProfileModuleParams setIsLoading={setIsLoading} {...props} />
      </PageTransition>
    </>
  )
}

export const QuizPage = (props) => {
  return (
    <>
      <PageTransition>
        <QuizModule {...props} />
      </PageTransition>
    </>
  )
}

export const QuickQuizPage = (props) => {
  return (
    <>
      <PageTransition>
        <QuickQuizModule {...props} />
      </PageTransition>
    </>
  )
}

export const EmployeeSearchPage = (props) => {
  return (
    <>
      <PageTransition>
        <SearchModule {...props} />
      </PageTransition>
    </>
  )
}

export const CreateQuizPage = (props) => {
  return (
    <>
      <PageTransition>
        <CreateQuizModule {...props} />
      </PageTransition>
    </>
  )
}

export const ReportPage = (props) => {
  return (
    <>
      <PageTransition>
        <ReportModule {...props} />
      </PageTransition>
    </>
  )
}

export const HRPage = (props) => {
  return (
    <>
      <PageTransition>
        <HRModule {...props} />
      </PageTransition>
    </>
  )
}

export const MethodPage = (props) => {
  return (
    <>
      <PageTransition>
        <MethodologyPage {...props} />
      </PageTransition>
    </>
  )
}