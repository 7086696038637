import { motion } from 'framer-motion';
import './MethodologyPage.css';


function MethodologyPage() {
    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, staggerChildren: 0.2 },
        },
    };

    const blockVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className='methodology-page__div'
        >
            <motion.h2 variants={containerVariants}>Методология ежеквартальной оценки</motion.h2>
            <motion.div variants={containerVariants}>
                <p>Ежеквартально мы проходим оценку эффективности деятельности сотрудников Pridex Plus.
                    Со второго квартала 2024 года оценка перенесена с платформы 1С на веб-сервис. Заполнение стало интуитивно более понятным и удобным. </p>
            </motion.div>
            <motion.div>
                <h2>Зачем мы оцениваем?</h2>
                <motion.ul variants={containerVariants}
                    initial="hidden"
                    animate="visible">
                    <motion.li variants={containerVariants}>
                        Повышаем эффективность
                    </motion.li>
                    <motion.li variants={containerVariants}>
                        Оцениваем эффективность деятельности
                    </motion.li>
                    <motion.li variants={containerVariants}>
                        Формируем единые стандарты поведения и работы, основанные на ценностях и целях компании;
                    </motion.li>
                    <motion.li variants={containerVariants}>
                        Ведем диалог, усиливаем взаимопонимание в команде
                    </motion.li>
                    <motion.li variants={containerVariants}>
                        Работаем над развитием и карьерным ростом
                    </motion.li>
                    <motion.li variants={containerVariants}>
                        Стимулируем сотрудников на плановые и сверхплановые достижения
                    </motion.li>
                </motion.ul>
            </motion.div>
            <motion.div>
                <h2>Что мы оцениваем?</h2>
                <motion.ul variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className='methodology-page__border-block'
                >
                    <p>
                        Оценка включает в себя два базовых компонента: Личная результативность и оценка по Ценностным компетенциям:
                    </p>
                    <motion.li variants={containerVariants}>
                        Личная результативность — оцениваем, что мы делаем: выполнение целей, производительность труда, полнота и качество.
                    </motion.li>
                    <motion.li variants={containerVariants}>
                        Оценка по ценностным компетенциям — оцениваем, как мы делаем: лидерские, командные и клиентские компетенции:
                        <ul>
                            <li>«Я лидер» — ответственность за результат своей работы, совершенствование процессов, а также своих навыков, стремление получить новые знания и опыт.</li>
                            <li>«Мы Команда» — готовность помогать коллегам для достижения общего результата, открытость, доверие и взаимоуважение.</li>
                            <li>«Все для клиента» — обеспечение превосходящего ожидания уровня услуг, максимально комфортного сервиса, даже в нестандартных ситуациях.</li>
                        </ul>
                    </motion.li>
                </motion.ul>
            </motion.div>
            <motion.div className="process-steps">
                <div className='step__wrapper'>
                    <motion.div className="step" variants={blockVariants}>
                        САМООЦЕНКА
                    </motion.div>
                    <div className="step-description">
                        Сотрудники заполняют самооценку по результативности и ценностям и направляют на согласование руководителю.
                    </div>
                </div>

                <div className='step__wrapper'>
                    <motion.div className="step" variants={blockVariants}>
                        ОЦЕНКА
                    </motion.div>
                    <div className="step-description">
                        Оценка согласующими руководителями с учетом лимитов.
                    </div>
                </div>
                <motion.div className='step__wrapper' variants={blockVariants}>
                    <div className="step" >
                        КАЛИБРОВКА
                    </div>
                    <div className="step-description">
                        Калибровка оценок по подразделениям, распределение оценок и анализ результатов.
                    </div>
                </motion.div>
                <motion.div className='step__wrapper' variants={blockVariants}>
                    <motion.div className="step" variants={blockVariants}>
                        УТВЕРЖДЕНИЕ
                    </motion.div>
                    <div className="step-description">
                        Руководитель функционального блока утверждает результаты оценки.
                    </div>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default MethodologyPage;