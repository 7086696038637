import { useEffect, useState } from 'react';
import './App.css';
import { AuthPage, ProfilePage, ProfilePageParams, QuickQuizPage, QuizPage, EmployeeSearchPage, CreateQuizPage, ReportPage, HRPage, MethodPage } from './utils/PagesTemplates';
import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import auth from './utils/Auth';
import api from './utils/Api';
import Header from './components/Header/Header';
import PrivateRoutes from './utils/ProtectedRoute';
import QuickQuizModule from './modules/QuickQuizModule/QuickQuizModule';
import useUserStore from './utils/stores/useUserStore';
import { getCookie, getToken, removeCookie, removeToken, setCookie } from './utils/cookieHandlers';
import Cookies from 'js-cookie';
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [loadingError, setLoadingError] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  const { fetchSettings } = useUserStore();

  useEffect(() => {
    const profile = getCookie('currentProfile');
    if (profile) {
      setUserProfile(JSON.parse(profile));
    }

    const token = getToken();
    if (token) {
      fetchAndSetUserData(token);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const fetchAndSetUserData = async (authToken) => {
    try {
      const user = await api.fetchUser(authToken);
      setCurrentUser(user);
      setCookie('currentUser', JSON.stringify(user), { expires: 7, path: '/' });
      const profile = await api.fetchProfile(authToken, user.id);
      const userProfile = profile.results[0];
      setCookie('currentProfile', JSON.stringify(userProfile), { expires: 7, path: '/' });
      await fetchSettings();
      setUserProfile(userProfile);
      setLoggedIn(true);
    } catch (error) {
      console.log(error);
      removeToken();
      removeCookie('currentUser');
      removeCookie('currentProfile');
      setLoggedIn(false);
    }
  };

  const onSignOut = async () => {
    try {
      setIsLoading(true);
      const token = getToken();
      if (token) {
        const res = auth.handleLogout(token);
      }
      removeToken();
      removeCookie('currentUser');
      removeCookie('currentProfile');
      setLoggedIn(false);
    } catch (error) {
      console.log(error);
      removeToken();
      removeCookie('currentUser');
      removeCookie('currentProfile');
      setLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      <div className="App">
        <Header isLoggedIn={isLoggedIn} onSignOut={onSignOut} />
        {isLoggedIn !== null &&
          <Routes location={location}>
            <Route
              path='/sign-in'
              element={isLoggedIn ? <Navigate to='/profile' /> : <AuthPage setLoggedIn={setLoggedIn} setLoadingError={setLoadingError} setUserProfile={setUserProfile} />}
            />
            <Route element={<PrivateRoutes isLoading={isLoading} isLoggedIn={isLoggedIn} loadingError={loadingError} setIsLoading={setIsLoading} currentUser={currentUser} />}>
              <Route path='/profile' element={<ProfilePage userProfile={userProfile} currentUser={currentUser} />} />
              <Route path='/quiz/:quizId' element={<QuizPage userProfile={userProfile} currentUser={currentUser} />} />
              <Route path='/quiz/quick-quiz/:managerId' element={<QuickQuizPage userProfile={userProfile} currentUser={currentUser} />} />
              <Route path='/profile/:profileId' element={<ProfilePageParams userProfile={userProfile} setIsLoading={setIsLoading} />} />
              {/* <Route path='/methodology' element={<MethodPage />} /> */}


              <Route path='/employee-search' element={<EmployeeSearchPage />} />
              <Route path='/create-quiz' element={<CreateQuizPage />} />
              <Route path='/report' element={<ReportPage />} />
              <Route path='/hr' element={<HRPage />} />
            </Route>
            <Route path='*' element={<Navigate to={isLoggedIn ? '/profile' : '/sign-in'} />} />
          </Routes>
        }
      </div>
    </AnimatePresence>
  );
}

export default App;
