import Select from 'react-select/async';
import { useState, useRef, useCallback, useEffect } from 'react';
import api from '../../utils/Api';
import { getToken } from '../../utils/cookieHandlers';

export const CreateQuizSelect = ({
    placeholder,
    onSelectedChange,
    isMulti,
    width,
    required,
    onChange
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const debounceTimeout = useRef(null);

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            display: 'flex',
            alignItems: 'center',
            width: width || '100%',
            fontFamily: 'Circe',
            height: '40px',
            zIndex: '20',
            borderRadius: '10px',
            borderColor: '#DAF0F9',
            textAlign: 'left',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused ? '1px solid #B5E2F4' : '1px solid #B5E2F4',
            },
            opacity: state.isDisabled ? 0.5 : 1,
            backgroundColor: state.isDisabled ? '#fff' : '#fff',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '10px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: state.isSelected ? '#48849C' : '#fff',
            color: state.isSelected ? '#fff' : '#000',
            '&:hover': {
                backgroundColor: state.isSelected ? '#48849C' : '#DAF0F9',
            },
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            paddingLeft: '10px',
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            width: '100%',
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            color: '#000',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            fontFamily: 'Circe',
            color: '#000',
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left',
            borderColor: '#DAF0F9',
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            zIndex: 9999,
            borderRadius: '10px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontSize: '14px',
            borderColor: '#DAF0F9',
            overflow: 'hidden',
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
        }),
        indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none',
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: '#6BC4E8',
        }),
        loadingIndicator: (baseStyles) => ({
            ...baseStyles,
            maxWidth: '50px'
        }),
    };

    const searchWithPagination = async (inputValue) => {
        if (!inputValue) return [];
        const token = getToken();
        try {
            setIsLoading(true);
            const response = await api.searchByAnyField(token, inputValue);
            return response.results.map((item) => ({
                label: `${item.full_name} - ${item.position.length > 30 ? item.position.slice(0, 30) + '...' : item.position}`,
                value: item.id,
            }));
        } catch (error) {
            console.error('Error fetching profiles:', error);
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedSearch = useCallback((inputValue, callback) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(async () => {
            const options = await searchWithPagination(inputValue);
            callback(options);
        }, 500);
    }, []);

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    const handleChange = (selectedOptions) => {
        onChange(selectedOptions);
    };
    
    return (
        <Select
            isMulti={isMulti}
            styles={selectStyles}
            placeholder={placeholder}
            isSearchable
            loadOptions={debouncedSearch}
            onChange={handleChange}
            required={required}
            isLoading={isLoading}
        />
    );
};