import Cookies from 'js-cookie';

export const getToken = () => {
  return Cookies.get('jwt');
};

export const setToken = (token) => {
  Cookies.set('jwt', token, { expires: 120, path: '/' });
};

export const removeToken = () => {
  Cookies.remove('jwt', { path: '/' });
};

export const setCookie = (name, value, options = {}) => {
  Cookies.set(name, value, { expires: 7, path: '/', ...options });
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name, { path: '/' });
};