export function getUniqueYearsOptions(data) {
    const uniqueYears = new Set();

    data.forEach(item => {
        if (item.status_doc_quiz_name === "Завершен") {
            const date = new Date(item.quarter);
            if (!isNaN(date)) {
                uniqueYears.add(date.getFullYear());
            }
        }
    });

    const sortedYears = Array.from(uniqueYears).sort((a, b) => b - a);

    let result = sortedYears.map(year => ({
        value: year,
        label: `${year} г.`
    }));

    if (result.length > 1) {
        result.unshift({
            value: "all",
            label: "Показать все"
        });
    }

    return result;
}
