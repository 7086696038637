import './CreateQuizModule.css';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/Api';
import CreateMassQuiz from './CreateMassQuiz';
import { CreateQuizSelect } from './CreateQuizSelect';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as Close } from '../../utils/images/close.svg';
import { getToken } from '../../utils/cookieHandlers';
function CreateQuizModule({ isOpened, setOpened }) {
    const navigate = useNavigate();
    const [createOne, setCreateOne] = useState(true);
    const [selectedEmployee, setEmployee] = useState(null);
    const [selectedRespondent, setRespondent] = useState(null)
    const [date, setDate] = useState(null);
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedSeason, setSeason] = useState(null);
    const [isMassOpened, setMassOpened] = useState(false);
    const popupRef = useRef();
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (popupRef.current && !popupRef.current.contains(event.target)) {
                    setOpened(false);
                }
            };
    
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [setOpened]);
    const getDateFromType = (selectedSeason) => {
        const seasons = {
            '1 Квартал': '-03-31',
            '2 Квартал': '-06-30',
            '3 Квартал': '-09-30',
            '4 Квартал': '-12-31'
        };
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const seasonStartDate = seasons[selectedSeason] || '';
        const formattedDate = `${currentYear}${seasonStartDate}`;
        return `${formattedDate}`;
    };

    const handleSubmit = async () => {
        try {
            const token = getToken();
            const formattedDate = getDateFromType(selectedSeason);
            const body = {
                "staff_id": `${selectedEmployee.value}`,
                "inspector_id": `${selectedRespondent.value}`,
                "quarter": formattedDate,
                "annual_assessment": false
            };

            const res = await api.createQuiz(token, body);
            setSuccess(true);
            setError(false);
        } catch (error) {
            setSuccess(false);
            setError(true);
            const errorMessage = Object.values(error.data).flat().join(' ');
            setErrorMessage(errorMessage);
            console.log(error);
        } finally {
            setEmployee({});
            setRespondent({});
            setDate(null);
        }
    };


    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (popupRef.current && !popupRef.current.contains(event.target)) {
    //             setOpened(false);
    //         }
    //         console.log('12313213');
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    return (
        <>
            <AnimatePresence>
                {isOpened &&
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'tween', duration: .3 }}
                        className='calibrate-popup__layout'
                        key='quiz-create-popup'
                    >
                        <div className='quiz__create__block' ref={popupRef}>
                            <Close onClick={() => setOpened(false)} />
                            <div className='quiz__create__div'>
                                <div className={`quiz__create__button${createOne ? '_active' : ''}`} onClick={() => setCreateOne(true)}>Одиночное</div>
                                <div className={`quiz__create__button${createOne ? '' : '_active'}`} onClick={() => setCreateOne(false)}>Массовое</div>
                            </div>
                            {createOne ?
                                <>
                                    <div className='quiz__create__form'>
                                        <div className='quiz__create__row'>
                                            <p>Выберите на кого создать:</p>
                                            <CreateQuizSelect onChange={(selectedOption) => setEmployee(selectedOption)} placeholder={'Начните вводить ФИО...'} />
                                        </div>
                                        <div className='quiz__create__row'>
                                            <p>Выберите проверяющего:</p>
                                            <CreateQuizSelect onChange={(selectedOption) => setRespondent(selectedOption)} placeholder={'Начните вводить ФИО...'} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                            <p className='quiz__create__p' style={{ marginBottom: 0 }}>Дата создания: </p>
                                            <div className='quiz__create__grid-s'>
                                                <div className={`quiz__create__grid-item-s${selectedSeason === '1 Квартал' ? '_active' : ''}`} onClick={() => {
                                                    setSeason('1 Квартал')
                                                }}>
                                                    <p style={{ fontWeight: 'normal' }}>1 Квартал</p>
                                                    <p>Январь - Март</p>
                                                </div>
                                                <div className={`quiz__create__grid-item-s${selectedSeason === '2 Квартал' ? '_active' : ''}`} onClick={() => {
                                                    setSeason('2 Квартал')
                                                }}>
                                                    <p style={{ fontWeight: 'normal' }}>2 Квартал</p>
                                                    <p>Апрель - Июнь</p>
                                                </div>
                                                <div className={`quiz__create__grid-item-s${selectedSeason === '3 Квартал' ? '_active' : ''}`} onClick={() => {
                                                    setSeason('3 Квартал')
                                                }}>
                                                    <p style={{ fontWeight: 'normal' }}>3 Квартал</p>
                                                    <p>Июль - Сентябрь</p>
                                                </div>
                                                <div className={`quiz__create__grid-item-s${selectedSeason === '4 Квартал' ? '_active' : ''}`} onClick={() => {
                                                    setSeason('4 Квартал')
                                                }}>
                                                    <p style={{ fontWeight: 'normal' }}>4 Квартал</p>
                                                    <p>Октябрь - Декабрь</p>
                                                </div>
                                            </div>
                                        </div>
                                        {isSuccess ? <p className='quiz__create__success'>Анкета успешно создана!</p> : null}
                                        {isError ? <p className='quiz__create__error'>Произошла ошибка при создании анкеты: {errorMessage}</p> : null}
                                        <button className='quiz__create__button-s' onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}

                                        >Создать анкету</button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='quiz__create__grid'>
                                        <div className='quiz__create__grid-item' onClick={() => {
                                            setSeason('1 Квартал')
                                            setMassOpened(true);
                                        }}>
                                            <p style={{ fontWeight: 'normal' }}>1 Квартал</p>
                                            <p>Январь - Март</p>
                                        </div>
                                        <div className='quiz__create__grid-item' onClick={() => {
                                            setSeason('2 Квартал')
                                            setMassOpened(true);
                                        }}>
                                            <p style={{ fontWeight: 'normal' }}>2 Квартал</p>
                                            <p>Апрель - Июнь</p>
                                        </div>
                                        <div className='quiz__create__grid-item' onClick={() => {
                                            setSeason('3 Квартал')
                                            setMassOpened(true);
                                        }}>
                                            <p style={{ fontWeight: 'normal' }}>3 Квартал</p>
                                            <p>Июль - Сентябрь</p>
                                        </div>
                                        <div className='quiz__create__grid-item' onClick={() => {
                                            setSeason('4 Квартал')
                                            setMassOpened(true);
                                        }}>
                                            <p style={{ fontWeight: 'normal' }}>4 Квартал</p>
                                            <p>Октябрь - Декабрь</p>
                                        </div>
                                    </div>
                                    <p className='quiz__create__p' style={{ marginTop: '15px' }}>Учтите, массовое создание анкет может длиться несколько минут.</p>
                                </>
                            }
                        </div>
                        <CreateMassQuiz selectedSeason={selectedSeason} isPopupOpened={isMassOpened} setPopupOpened={setMassOpened} />
                    </motion.div>}
            </AnimatePresence>
        </>
    )
}

export default CreateQuizModule;
