import api from '../../utils/Api';
import { getToken } from '../../utils/cookieHandlers';
import cross from '../../utils/images/cross.svg';
export default function BUBPItem({ item, isEditable, setBUBPPopupOpened, setSelectedHR, fetchHrBuList}) {

    const handleClick = () => {
        setSelectedHR(item);
        setBUBPPopupOpened(true);
    }

    const handleDeleteChain = async (id) => {
        try {
            const token = getToken();
            const res = await api.deleteBU(token, id);
        } catch(error) {
            console.log(error);
        } finally {
            await fetchHrBuList();
        }
    }

    return (
        <>
            <div className='hr-module__bu-item'>
                <p className='hr-module__hr-name'>{item.hr_full_name}</p>
                <div className='hr-module__bu-item__bu-list'>
                    {item?.items.map(item => {
                        return (
                            <div className='hr-module__bu-item__bu-list__item'>
                                <p>{item.bu_full_name}</p>
                                {isEditable && <button onClick={() => handleDeleteChain(item.id)}><img src={cross}></img></button>}
                            </div>
                        )
                    })}
                    {isEditable && <button className='hr-module__bu-item__add-chain-button' onClick={handleClick}>Добавить связь</button>}
                </div>
            </div>
        </>
    )
}