import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchModule.css';
import { calculateCurrentPeriod, renderAnswer } from '../QuizModule/utils/UtilityFunctions';
import useUserStore from '../../utils/stores/useUserStore';
import api from '../../utils/Api';
import { ReactComponent as ArrowRight } from '../../utils/images/arrow_left.svg';
import { getToken } from '../../utils/cookieHandlers';

export const QuizSearchListItem = forwardRef(({ item }, ref) => {
    const navigate = useNavigate();
    const { userProfile, settings } = useUserStore();
    const [isLoading, setIsLoading] = useState(false);
    const currentPeriod = calculateCurrentPeriod(item, settings).currentPeriod;
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleItemClick = (item) => {
        openInNewTab(`/quiz/${item.id}?calibrate=true&fromSearch=true`);
    };
    const patchCalibrateQuiz = async () => {
        try {
            setIsLoading(true);
            const token = getToken();
            const res = api.patchCalibrateQuiz(token, item.id, userProfile.id);
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
                handleItemClick(item);
            }, 1500)
        }
    }

    const handleClickOnRow = (e) => {
        if (e.target.className !== 'search-list__row__button') {
            navigate(`/profile/${item.staff_id}`);
        }
    }

    return (
        <div className="search-list__row" ref={ref} onClick={(e) => handleClickOnRow(e)}>
            <div className="search-list__column">
                <p>{item.full_name}</p>
                <p>{item.staff_position}</p>
            </div>
            <div className="search-list__column">
                <p>{item.staff_department}</p>
                <p>{item.full_name_inspector}</p>
            </div>
            <div className="search-list__column" id='slclsc'>
                <div className='search-list__column__scores'>
                    {item.answer_on_question_for_staff
                        .filter(ans => ans.category_name === 'Итоговая оценка')
                        .map((ans, index) => (
                            <div key={index} className={ans.answer || ans.answer === 0 ? 'empty-answer' : 'null-answer'}>
                                {(ans.answer || ans.answer === 0) && renderAnswer(ans.answer)}
                            </div>
                        ))}
                </div>
                <div className='search-list__column__scores'>
                    {item.answer_on_question_for_inspector
                        .filter(ans => ans.category_name === 'Итоговая оценка')
                        .map((ans, index) => (
                            <div key={index} className={ans.answer || ans.answer === 0 ? 'empty-answer' : 'null-answer'}>
                                {(ans.answer || ans.answer === 0) && renderAnswer(ans.answer)}
                            </div>
                        ))}
                </div>
            </div>
            <div className="search-list__column" id={item.status_doc_quiz_name === 'Завершен' ? 'slclsc' : null}>
                {item.status_doc_quiz_name === 'Завершен' &&
                    item.answer_on_question_for_calibration
                        .filter(ans => ans.category_name === 'Итоговая оценка')
                        .sort((a, b) => a.number_question - b.number_question)
                        .map((ans, index) => (
                            <div key={index} className={ans.answer || ans.answer === 0 ? 'calibrate-answer_selected' : 'null-answer'}>
                                {(ans.answer || ans.answer === 0) && renderAnswer(ans.answer)}
                            </div>
                        ))
                }
                {currentPeriod === 'calibration' && item.status_doc_quiz_name !== 'Завершен' && item.status_doc_quiz_name === 'Оценен руководителем' &&
                    <button
                        className='search-list__row__button'
                        onClick={() => patchCalibrateQuiz()}
                    >
                        {isLoading ? 'Загрузка...' : 'Калибровать'}
                    </button>
                }
            </div>
        </div>
    );
});


export const EmployeeSearchItem = forwardRef(({ item }, ref) => {
    const navigate = useNavigate();

    const handleClickOnRow = () => {
        navigate(`/profile/${item.id}`);
    };

    return (
        <div className='employee-search__item__div' ref={ref} onClick={handleClickOnRow}>
            <div className='employee-search__fullname'>{item.full_name}</div>
            <div className='employee-search__department'>{item.department}</div>
            <div className='employee-search__position'>{item.position}</div>
            <ArrowRight style={{ transform: 'rotate(180deg)' }} />
        </div>
    );
});