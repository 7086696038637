import React, { useEffect, useState } from 'react';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import 'overlayscrollbars/overlayscrollbars.css';
import './HRModule.css';
import { motion } from 'framer-motion';
import { ReactComponent as Report } from '../../utils/images/excel.svg';
import { ReactComponent as Search } from '../../utils/images/search_rofl.svg';
import { ReactComponent as QuizSend } from '../../utils/images/quiz.svg';
import { ReactComponent as Telegram } from '../../utils/images/telegram.svg';
import { useNavigate } from 'react-router-dom';
import arrow_left from "../../utils/images/arrow_left.svg";
import useUserStore from '../../utils/stores/useUserStore';
import ReportModule from '../ReportModule/ReportModule';
import CreateQuizModule from '../CreateQuizModule/CreateQuizModule';
import MailingModule from '../MailingModule/MailingModule';
import api from '../../utils/Api';
import BUBPItem from './BUBPItem';
import BUBPPopup from './BUBPPopup';
import DragDropFileInput from '../../components/DragAndDrop/DragAndDrop';
import { getToken } from '../../utils/cookieHandlers';

function HRModule() {
    const navigate = useNavigate();

    const { isLoading, userProfile, buId } = useUserStore();
    const [isReportOpened, setReportOpened] = useState(false);
    const [isCreateQuizOpened, setCreateQuizOpened] = useState(false);
    const [isMailingOpened, setMailingOpened] = useState(false);
    const [hrBuList, setHrBuList] = useState([]);
    const [isBUBPEditable, setBUBPEditable] = useState(false);
    const [isBUBPPopupOpened, setBUBPPopupOpened] = useState(false);
    const [selectedHR, setSelectedHR] = useState(null);
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('ru-RU', { month: 'long' });
    const dayOfWeek = currentDate.toLocaleString('ru-RU', { weekday: 'long' });
    const isBP = userProfile?.roles.some((role) => role.name === 'BP');

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, staggerChildren: 0.2 },
        },
    };

    const blockVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    const fetchHrBuList = async () => {
        try {
            const token = getToken();
            const res = await api.getBuHrList(token);
            setHrBuList(res.results);
        } catch (err) {
            console.error('Ошибка при загрузке списка HR BU:', err);
        }
    };

    useEffect(() => {
        fetchHrBuList();
    }, []);

    if (isLoading) return null;

    return (
        <>
            <div className='hr-module__page'>
                <button
                    className="search-module__button-div"
                    onClick={() => navigate('/profile')}
                >
                    <img className="search-module__button" src={arrow_left} alt="" />
                    <p>В профиль</p>
                </button>
                <motion.div
                    className="hr-module__div"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >

                    <motion.div className="hr-module__header">
                        <div className="hr-module__header__subheader">
                            {/* <p>Текущий квартал: <span>4 квартал 2024</span></p> */}
                        </div>
                        <motion.div className="hr-module__header__body" variants={blockVariants}>
                            <div className="hr-module__welcome-block">
                                <h2>Добро пожаловать, {userProfile?.first_name}</h2>
                                <p>Это ваш личный кабинет</p>
                            </div>
                            <div className="hr-module__date-block">
                                <div className="hr-module__date-circle">{day}</div>
                                <div className="hr-module__date-text">
                                    <p>{dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)},</p>
                                    <p style={{ fontWeight: 'bold' }}>{month.charAt(0).toUpperCase() + month.slice(1)}</p>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                    <motion.div className="hr-module__main-block" variants={containerVariants}>
                        <motion.div className="hr-module__buttons-row" variants={containerVariants}>
                            <motion.button
                                className="hr-module__s-button"
                                onClick={() => navigate('/employee-search')}
                            >
                                Перейти к поиску <Search />
                            </motion.button>
                            <motion.button
                                className="hr-module__s-button"
                                onClick={() => setCreateQuizOpened(true)}
                            >
                                Рассылка анкет <QuizSend />
                            </motion.button>
                            <motion.button
                                className="hr-module__s-button grey-button"
                                onClick={() => setReportOpened(true)}
                            >
                                Выгрузка отчета <Report />
                            </motion.button>
                            <motion.button
                                className="hr-module__s-button grey-button"
                                onClick={() => setMailingOpened(true)}
                            >
                                Создание рассылки <Telegram />
                            </motion.button>
                        </motion.div>
                        <motion.div className="hr-module__dashboard-block" variants={containerVariants}>
                            <motion.div className="hr-module__left-dash">
                                <div className='hr-module__dash__header'>
                                    <h2>Информация по бизнес-юнитам</h2>
                                    {!isBP && <button onClick={() => setBUBPEditable(!isBUBPEditable)}>{isBUBPEditable ? 'Сохранить' : 'Редактировать'}</button>}
                                </div>
                                {hrBuList?.length > 0
                                    ?
                                    <OverlayScrollbarsComponent
                                        options={{
                                            scrollbars: {
                                                autoHide: "leave",
                                            },
                                        }}
                                        style={{ width: '100%', padding: '0px 15px' }}
                                    >
                                        <div className='hr-module__bu-list'>

                                            {hrBuList?.map((item) => {
                                                return <BUBPItem fetchHrBuList={fetchHrBuList} setSelectedHR={setSelectedHR} item={item} isEditable={isBUBPEditable} setBUBPPopupOpened={setBUBPPopupOpened} />
                                            })}

                                        </div>
                                    </OverlayScrollbarsComponent>
                                    :
                                    <div className='hr-module__empty-warning'><p>Информация не найдена.</p></div>
                                }
                            </motion.div>
                            <motion.div className="hr-module__right-dash">
                                {!isBP ?
                                    <>
                                        <div className='hr-module__except-list__div'>
                                            <h2>Загрузка списка на исключение.</h2>
                                            <p>В этой форме вы можете загрузить Excel файл со списком табельных номеров сотрудников, на кого не будут создаваться анкеты.</p>
                                            <DragDropFileInput />
                                        </div>
                                        <div className='hr-module__except-list__div'>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='hr-module__except-list__div'>
                                        </div>
                                        <div className='hr-module__except-list__div'>
                                        </div>
                                    </>
                                }
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
                <ReportModule isOpened={isReportOpened} setOpened={setReportOpened} />
                <CreateQuizModule isOpened={isCreateQuizOpened} setOpened={setCreateQuizOpened} />
                <MailingModule isOpened={isMailingOpened} setOpened={setMailingOpened} />
                <BUBPPopup isOpened={isBUBPPopupOpened} setOpened={setBUBPPopupOpened} selectedHR={selectedHR} />
            </div>
        </>
    );
}

export default HRModule;
