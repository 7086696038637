import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as Close } from '../../utils/images/close.svg';
import './HRModule';
import api from '../../utils/Api';
import { CreateQuizSelect } from '../CreateQuizModule/CreateQuizSelect';
import { getToken } from '../../utils/cookieHandlers';

const BUBPPopup = ({ setOpened, isOpened, selectedHR }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedBU, setSelectedBU] = useState(null);
    const popupRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpened(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setOpened]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDisabled(true);
        setMessage('');
        setIsSuccess(false);
        setIsError(false);

        try {
            const token = getToken();
            const res = await api.postBU(token, selectedHR.hr_id, selectedBU.value);
            setIsSuccess(true);
            setMessage('Бизнес-юнит успешно добавлен!');
        } catch (error) {
            console.error(error);
            setIsError(true);
            setMessage('Ошибка при добавлении бизнес-юнита. Попробуйте позднее.');
        } finally {
            setIsDisabled(false);
            setTimeout(() => {
                setOpened(false);
                setIsSuccess(false);
                setIsError(false);
                setMessage('');
            }, 3000);
        }
    };

    useEffect(() => {
        console.log(selectedBU);
        console.log(selectedHR);
    }, [selectedBU, selectedHR]);

    return (
        <AnimatePresence>
            {isOpened && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'tween', duration: 0.3 }}
                    className="calibrate-popup__layout"
                >
                    <motion.form
                        className="bubpopup__div"
                        ref={popupRef}
                        onSubmit={handleSubmit}
                    >
                        <div className='bubpopup__div__header'>
                            <div style={{width: '30px', height: '30px'}}></div>
                            <p>
                                Вы добавляете бизнес-юнит для:{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {selectedHR.hr_full_name}
                                </span>
                            </p>
                            <Close onClick={() => setOpened(false)} />
                        </div>

                        <CreateQuizSelect
                            required={true}
                            placeholder="Введите ФИО руководителя Бизнес-Юнита"
                            onChange={setSelectedBU}
                        />
                        {message && (
                            <div
                                style={{
                                    fontSize: '14px',
                                    color: isSuccess ? 'green' : 'red',
                                }}
                            >
                                {message}
                            </div>
                        )}
                        <button
                            type="submit"
                            className="bubpopup__button"
                            disabled={isDisabled}
                        >
                            Подтвердить
                        </button>
                    </motion.form>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default BUBPPopup;