import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './QuizStatusBar.css';
import editIcon from '../../utils/images/edit.svg';
import avatarPlaceholder from '../../utils/images/avatar.svg';
import api from '../../utils/Api';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/TimeConvertFunctions';
import useQuizStore from '../../utils/stores/useQuizStore';
import useUserStore from '../../utils/stores/useUserStore';
import { QuizStatusBarSelect } from './QuizStatusBarSelect';
import { statusDocQuiz } from '../../utils/Constants';
import DeleteQuizPopup from './DeleteQuizPopup';
import { calculateCompletion } from '../../modules/QuizModule/utils/UtilityFunctions';
import { getToken } from '../../utils/cookieHandlers';

function QuizStatusBar({ setRespondentOpened }) {
    const [respondent, setRespondent] = useState(null);
    const [quizUser, setQuizUser] = useState(null);
    const [quizStatus, setQuizStatus] = useState(null);
    const [isPopupOpened, setPopupOpened] = useState(false);
    const [progressData, setProgressData] = useState();
    const navigate = useNavigate();
    const { quiz, isYours, isInspector, setIsLoading, isLoading, deadlineDateInspector, deadlineDateStaff, currentPeriod } = useQuizStore();
    const { userProfile } = useUserStore();

    const hasRole = (roleName) => userProfile.roles.some(role => role.name === roleName);
    const initialStatus = statusDocQuiz.find(option => option.value === quiz?.status_doc_quiz);



    useEffect(() => {
        setQuizStatus(initialStatus);
        fetchRespondentData();
    }, [quiz?.id]);

    const fetchRespondentData = async () => {
        setIsLoading(true);
        try {
            const token = getToken();
            const [respondentData, userData] = await Promise.all([
                api.fetchProfileByProfileId(token, quiz.inspector_id),
                api.fetchProfileByProfileId(token, quiz.staff_id)
            ]);
            setRespondent(respondentData);
            setQuizUser(userData);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const patchCalibrateQuiz = async () => {
        setIsLoading(true);
        try {
            const token = getToken();
            await api.patchCalibrateQuiz(token, quiz.id, userProfile.id);
        } catch (error) {
            console.error(error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
                navigate(`/quiz/${quiz.id}?calibrate=true`);
                navigate(0);
            }, 1000);
        }
    };

    const handleChangeQuizStatus = async (newStatus) => {
        if (!newStatus) return;
        try {
            const token = getToken();
            await api.patchQuizStatus(token, quiz.id, newStatus.value);
            setQuizStatus(newStatus);
        } catch (error) {
            console.error(error);
        }
    };

    const convertDateToQuarter = (inputDate) => {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const quarter = ['I', 'II', 'III', 'IV'][Math.floor(date.getMonth() / 3)];
        return `${quarter} квартал ${year}`;
    };

    const colorMatch = {
        "Создана": { color: "#E86B83", text: "Не пройдена" },
        "Оценен сотрудником": { color: "#003B73", text: "Пройдена сотрудником" },
        "Оценен руководителем": { color: "#2C7865", text: "Оценен руководителем" },
        "Завершен": { color: "green", text: "Оценка завершена" }
    };

    if (!quiz) return null;

    return (
        <>
            <motion.div
                className="quiz__statusbar__container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <p className="quiz__statusbar__title">Ежеквартальная оценка результативности</p>
                <p className="quiz__statusbar__title" style={{ fontWeight: 'bold' }}>
                    {convertDateToQuarter(quiz.quarter)}
                </p>
                <div className="quiz__statusbar">
                    <UserBlock quiz={quiz} />
                    <DateBlock
                        quiz={quiz}
                        userProfile={userProfile}
                        isInspector={isInspector}
                        isYours={isYours}
                        deadlineDateInspector={deadlineDateInspector}
                        deadlineDateStaff={deadlineDateStaff}
                        quizStatus={quizStatus}
                        handleChangeQuizStatus={handleChangeQuizStatus}
                        colorMatch={colorMatch}
                    />
                </div>
                {userProfile.roles.some(role => role.name === 'HR') && !isYours && (
                    <RespondentSection
                        respondent={respondent}
                        setRespondentOpened={setRespondentOpened}
                        isLoading={isLoading}
                        patchCalibrateQuiz={patchCalibrateQuiz}
                        currentPeriod={currentPeriod}
                        hasRole={hasRole}
                        setPopupOpened={setPopupOpened}
                    />
                )}
            </motion.div>
            <DeleteQuizPopup quizId={quiz.id} isOpened={isPopupOpened} setIsOpened={setPopupOpened} />
        </>
    );
}

const UserBlock = ({ quiz }) => (
    <div className="quiz__statusbar__user-block">
        <img src={avatarPlaceholder} alt="User Avatar" />
        <div className="quiz__statusbar__name-block">
            <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{quiz.full_name}</p>
            <p style={{ fontSize: '14px' }}>{quiz.staff_department}</p>
            <p style={{ fontSize: '14px' }}>{quiz.staff_position}</p>
        </div>
    </div>
);

const DateBlock = ({
    quiz,
    userProfile,
    isInspector,
    isYours,
    deadlineDateInspector,
    deadlineDateStaff,
    quizStatus,
    handleChangeQuizStatus,
    colorMatch
}) => (
    <div className="quiz__statusbar__date-block">
        {userProfile.roles.some(role => role.name === 'HR') ? (
            <div className="quiz__statusbar__select-div">
                <p>Статус: </p>
                <QuizStatusBarSelect
                    onChange={handleChangeQuizStatus}
                    options={statusDocQuiz}
                    value={quizStatus}
                    placeholder="Выберите статус..."
                />
            </div>
        ) : (
            <div className='quiz__statusbar__row'>
                <p>Статус анкеты: </p><span style={{ color: colorMatch[quiz.status_doc_quiz_name]?.color, fontWeight: 'bold' }}>
                    {colorMatch[quiz.status_doc_quiz_name]?.text}
                </span>
            </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <DateField label="Дата рассылки" value={formatDate(quiz.time_create)} />
            <DateField
                label={quiz.status_doc_quiz_name === 'Завершен' ? "Дата завершения" : "Дата окончания"}
                value={formatDate(isInspector || !isYours ? deadlineDateInspector : deadlineDateStaff)}
            />
            <div className='quiz__statusbar__row'><p>Руководитель:</p><span>{quiz.full_name_inspector}</span></div>
        </div>
    </div>
);

const DateField = ({ label, value }) => (
    <div className='quiz__statusbar__row'>
        <p>{label}:</p>
        <span style={{ marginLeft: 'auto' }}>{value}</span>
    </div>
);

const RespondentSection = ({ respondent, setRespondentOpened, isLoading, patchCalibrateQuiz, currentPeriod, hasRole, setPopupOpened }) => (
    <div className="quiz__statusbar_add">
        <div className="quiz__statusbar__respondent-div" title="Вы можете поменять руководителя в этой анкете">
            <p className="quiz__statusbar__status-p" onClick={() => setRespondentOpened(true)}>
                Руководитель: <span>{respondent && `${respondent.last_name} ${respondent.first_name} ${respondent.surname}`}</span>
            </p>
            <img src={editIcon} alt="Edit" className="quiz__statusbar__status-img" onClick={() => setRespondentOpened(true)} />
        </div>
        {hasRole('HR') && currentPeriod === 'calibration' && (
            <button className="quiz__statusbar__button" onClick={patchCalibrateQuiz}>
                {isLoading ? 'Загрузка...' : 'Калибровать'}
            </button>
        )}
        {hasRole('HR') && (
            <button className="quiz__statusbar__delete-button" onClick={() => setPopupOpened(true)}>
                {isLoading ? 'Загрузка...' : 'Удалить анкету'}
            </button>
        )}
    </div>
);

export default QuizStatusBar;
