import './ProfileModule.css';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserInfo from "../../components/UserInfo/UserInfo";
import ScoreModule from "../ScoreModule/ScoreModule";
import EmployeeModule from "../EmployeeModule/EmployeeModule";
import QuickActions from "../../components/QuickActions/QuickActions";
import LoadingComponent from '../../utils/LoadingComponent';
import ErrorComponent from '../../utils/ErrorComponent';
import api from '../../utils/Api';
import arrow_left from '../../utils/images/arrow_left.svg';
import { motion } from 'framer-motion';
import useUserStore from '../../utils/stores/useUserStore';
import ActualScoreModule from '../ScoreModule/ActualScoreModule';
import { getToken } from '../../utils/cookieHandlers';
const ProfileModuleParams = ({ loadingError, currentUser, userProfile }) => {
    const { profileId } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const fromSearch = queryParams.get("fromSearch");
    const [fetchedProfile, setFetchedProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isYours, setIsYours] = useState(false);
    const [isError, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            if (profileId) {
                try {
                    setIsLoading(true);
                    const token = getToken();
                    const res = await api.fetchProfileByProfileId(token, profileId);
                    setFetchedProfile(res);
                } catch (error) {
                    console.log(error);
                    setError(error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchProfile();
    }, [profileId]);

    useEffect(() => {
        if (fetchedProfile) {
            document.title = `Pridex Plus | ${fetchedProfile?.last_name} ${fetchedProfile?.first_name}`;
        }

    }, [fetchedProfile])

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (isError) {
        return <ErrorComponent isError={isError} />;
    }

    return (
        <>
            {fetchedProfile ? (
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                    >
                        <div className="user-info__div" style={{ marginTop: '55px' }}>
                            {fromSearch ? (
                                <button className="user-info__button-div" onClick={() => navigate('/employee-search')}>
                                    <img className="quiz-module__button" src={arrow_left} alt="" />
                                    <p>Назад к поиску</p>
                                </button>
                            ) : (
                                <button className="user-info__button-div" onClick={() => navigate(-1)}>
                                    <img className="quiz-module__button" src={arrow_left} alt="" />
                                    <p>Назад</p>
                                </button>
                            )}
                            <UserInfo fetchedProfile={fetchedProfile} />
                        </div>
                        <ActualScoreModule />
                        <EmployeeModule userProfile={fetchedProfile} title={'Сотрудники'} isYours={false} />
                        <ScoreModule userProfile={fetchedProfile} currentUser={userProfile} isYours={isYours} />
                    </motion.div>
                </>
            ) : (
                <div>Профиль не найден</div>
            )}
        </>
    );
};

export default ProfileModuleParams;
