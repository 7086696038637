import { useEffect, useRef, useState } from 'react';
import './CreateQuizModule.css';
import api from '../../utils/Api';
import { motion } from 'framer-motion';
import cross from '../../utils/images/cross.svg';
import LoadingComponent from '../../utils/LoadingComponent';
import { getToken } from '../../utils/cookieHandlers';

function CreateMassQuiz({ isPopupOpened, setPopupOpened, selectedSeason }) {
    const popupRef = useRef();
    const [buttonState, setButtonState] = useState('Подтвердить');
    const [date, setDate] = useState(null);
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);

    useEffect(() => {
        setButtonState('Подтвердить');
        setSuccess(false);
        setError(false);
    }, [isPopupOpened])

    const getDateFromType = (selectedSeason) => {
        const seasons = {
            '1 Квартал': '-03-31',
            '2 Квартал': '-06-30',
            '3 Квартал': '-09-30',
            '4 Квартал': '-12-31'
        };
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const seasonStartDate = seasons[selectedSeason] || '';
        const formattedDate = `${currentYear}${seasonStartDate}`;
        return `${formattedDate}`;
    };

    useEffect(() => {
        setDate(getDateFromType(selectedSeason));
    }, [selectedSeason]);


    const handleSubmit = async () => {
        try {
            setButtonState('Создание...');
            const body = {
                "quarter": date,
                "annual_assessment": false
            }
            const res = await api.createMassQuiz(getToken(), body);
            console.log(res);
            setSuccess(true);
            setError(false);
        } catch (error) {
            setSuccess(false);
            setError(true);
            console.log(error.data);
        } finally {
            setButtonState('Подтвердить');
        }
    }



    return (
        <>
            {isPopupOpened && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'spring', duration: .7 }}
                    className="quiz__create-popup__layout"
                >
                    <div ref={popupRef} className="change-respondent-popup__body">
                        <h2 className='quiz__create-popup__title'>Массовое создание анкет</h2>
                        <p className='quiz__create-popup__p'>Нажатие на кнопку "Подтвердить" создаст каждому сотруднику анкету. Выбранный квартал: <span>{selectedSeason}</span></p>
                        <p className='quiz__create-popup__p'>Дата создания: <span>{date}</span></p>
                        {isSuccess ? <p className='quiz__create__success' style={{padding: '10px'}}>Анкеты успешно созданы!</p> : null}
                        {isError ? <p className='quiz__create__error' style={{padding: '10px'}}>Произошла ошибка при создании анкет.</p> : null}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                            {!isSuccess &&
                                <button className='change-respondent-popup__button'
                                    style={{ margin: '0px' }}
                                    disabled={buttonState === 'Создание...'}
                                    onClick={() => {
                                        handleSubmit();
                                    }
                                    }>{buttonState}</button>}
                            <button className='change-respondent-popup__button-e' onClick={() => {
                                setPopupOpened(false);
                            }
                            }>Закрыть</button>
                        </div>
                    </div>
                </motion.div >
            )
            }
        </>
    );
}

export default CreateMassQuiz;
